import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Typography,
  Grid,
  Paper,
  Button,
  DialogContent,
  Dialog,
  DialogActions,
} from '@material-ui/core';
// @icons
import { VpnKey, DoneAll, Work, DoubleArrow } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
import { formatCurrency } from 'utilities/handleCurrency';
// @components
import Content from 'components/Layout/Content';
// @local
import ReactGA from 'react-ga';
import { NotAcceptingRenewalsDialog } from 'components/StartRenewal/NotAcceptingRenewals';
import renewalImage from '../../assets/img/renewals.png';
import home1 from '../../assets/img/home-1.png';
import home2 from '../../assets/img/home-2.png';
import home3 from '../../assets/img/home-3.png';
import imac1 from '../../assets/img/imac1.png';
import imac2 from '../../assets/img/imac2.png';
import imac3 from '../../assets/img/imac3.png';
import sortedResponsive from '../../assets/img/sorted-responsive.png';
import useStyles from './styles';
import BaseFooterTopStyles from '../Layout/BaseFooterTopStyles';
import FAQTableStyles from '../Layout/FAQTableStyles';

const Home = observer(() => {
  const store = useStore();
  const history = useHistory();

  const classes = useStyles({
    dragActive: true,
    whitelabel: store.whitelabel,
    company: store.companies.company,
  });

  const faqTableStyles = FAQTableStyles({});

  enum ShowMoreImages {
    imacImg1,
    imacImg2,
    imacImg3,
    homeImg1,
    homeImg2,
    homeImg3,
    renewalImg,
    sortedResponsiveImg,
  }

  const { userId } = store.auth;
  const { managedVariables } = store.managedVariables;

  const footerStyles = BaseFooterTopStyles();
  const featureTextWithWidth = [classes.featureText, classes.width200].join(
    ' ',
  );
  const tableRightTextStyles = {
    root: faqTableStyles.tableTextRight,
  };

  const rootPaperRemove = { root: classes.paperRemove };

  const typeHeadPaddingStyles = [classes.typoHeadLeft, classes.padding10].join(
    ' ',
  );

  const rootButtonStyles = { root: classes.buttonStyle };

  const [open, setOpen] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [serviceFeeValue, setServiceFeeValue] = React.useState<number | null>(
    null,
  );

  const images: Array<string> = [
    imac1,
    imac2,
    imac3,
    home1,
    home2,
    home3,
    renewalImage,
    sortedResponsive,
  ];
  const [currentImage, setCurrentImage] = React.useState(0);

  const handleRenew = (action: 'sorted >>' | 'sign up' | 'click renew') => {
    ReactGA.event({
      category: 'renewal initiation',
      action,
      dimension1: `${userId}` || undefined,
    });
    if (!userId) {
      history.push('/signup');
    } else {
      history.push('/manage-vehicles');
    }
  };

  const handleSortedClick = () => handleRenew('sorted >>');
  const handleSignupClick = () => handleRenew('sign up');
  const handleRenewClick = () => handleRenew('click renew');

  const handleClickOpen = (index: number) => {
    setCurrentImage(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentImage(0);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  React.useEffect(() => {
    if (managedVariables != null && managedVariables[0] != null) {
      setServiceFeeValue(managedVariables[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(managedVariables)]);

  React.useEffect(() => {
    if (managedVariables === null || managedVariables.length === 0) {
      store.managedVariables.get();
    }
    // setTimeout(() => setShowMessage(true), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (userId != null) {
      store.vehicles.getVehicles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div className={classes.cover}>
      {/* <div className={classes.banner}>
        <Typography
          variant="body1"
          align="center"
          className={classes.bannerText}
        >
        Hi loyal customers. Sorted's services are temporarily unavailable but we will be back up and running soon. Thanks for your continued support!
        </Typography>
      </div> */}
      <div className={classes.darkBanner}>
        <Content>
          <Grid container direction="column">
            <Grid item xs="auto">
              <div className={classes.headTop}>
                <Typography
                  variant="h2"
                  align="center"
                  className={`${classes.companyFont} ${classes.padding10} ${classes.homeHead}`}
                >
                  Get your vehicle licence disc
                  <Button
                    variant="contained"
                    classes={rootButtonStyles}
                    color="secondary"
                    onClick={handleSortedClick}
                    endIcon={<DoubleArrow className={classes.blackIcon} />}
                  >
                    SORTED
                  </Button>
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  className={`${classes.companyFont} ${classes.homeText} ${classes.textSecond}`}
                >
                  Don&apos;t give your licence renewals a second thought.
                  {!store.whitelabel &&
                    'Sorted is a web app that does it all for you.'}
                </Typography>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleRenewClick}
                    size="large"
                  >
                    <b>Click to Renew</b>
                  </Button>
                </div>
              </div>
            </Grid>

            {!store.whitelabel && (
              <Grid item xs="auto">
                <Grid container className={classes.homeLanding}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className={classes.homeBackLeft}
                  >
                    <Paper
                      onClick={() => handleClickOpen(ShowMoreImages.imacImg1)}
                      classes={rootPaperRemove}
                      className={`${classes.homeImage}`}
                    >
                      <img
                        src={imac1}
                        className={classes.homeMainImage}
                        alt="home 1"
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className={classes.homeBackCenter}
                  >
                    <Paper
                      onClick={() => handleClickOpen(ShowMoreImages.imacImg2)}
                      classes={rootPaperRemove}
                      className={classes.homeImage}
                    >
                      <img
                        src={imac2}
                        className={classes.homeMainImage}
                        alt="home 2"
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className={classes.homeBackRight}
                  >
                    <Paper
                      onClick={() => handleClickOpen(ShowMoreImages.imacImg3)}
                      classes={rootPaperRemove}
                      className={classes.homeImage}
                    >
                      <img
                        src={imac3}
                        className={classes.homeMainImage}
                        alt="home 3"
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Content>
      </div>

      {!store.whitelabel && (
        <Content containerClass={`${classes.contentMid}`}>
          <Grid container justify="space-around" spacing={4}>
            <Grid item xs={12} sm={4} md={4}>
              <div className={`${classes.infoHome} ${classes.infoGrid}`}>
                <VpnKey fontSize="large" className={classes.icon} />
                <Typography
                  variant="h4"
                  align="center"
                  className={`${classes.padding10} ${classes.infoLight}`}
                >
                  Simple Setup
                </Typography>
                <p className={classes.iconText}>
                  Sign up for free in 2 minutes. Just upload a few documents and
                  enter your address
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.infoHome}>
                <DoneAll fontSize="large" className={classes.icon} />
                <Typography
                  variant="h4"
                  align="center"
                  className={`${classes.padding10} ${classes.infoLight}`}
                >
                  No forms. No queues
                </Typography>
                <p className={classes.iconText}>
                  All it takes is a few clicks and we&apos;ll handle the rest.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className={classes.infoHome}>
                <Work fontSize="large" className={classes.icon} />
                <Typography
                  variant="h4"
                  align="center"
                  className={`${classes.padding10} ${classes.infoLight}`}
                >
                  Email reminders
                </Typography>
                <p className={classes.iconText}>
                  No more forgetting to renew. We&apos;ll automatically remind
                  you in good time.
                </p>
              </div>
            </Grid>
          </Grid>
        </Content>
      )}
      {!store.whitelabel && (
        <Content containerClass={`${classes.contentMidWhite}`}>
          <Grid container justify="space-between" spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="h4"
                align="center"
                className={typeHeadPaddingStyles}
              >
                Add and manage all of your vehicles, whether 1 or 100.
              </Typography>
              <Typography
                variant="body1"
                align="center"
                className={typeHeadPaddingStyles}
              >
                Whether you own one car, run a dealership or have an entire
                vehicle fleet to manage, Sorted is the service for you. All it
                takes is just a few clicks to renew your vehicle licenses, and
                we’ll get the rest of it sorted
              </Typography>
              <Typography
                variant="body1"
                align="center"
                className={`${classes.typoTextLeft} ${classes.padding10}`}
              >
                No forms, no queueing. Plus you can keep track of each vehicle
                and renewal in real-time, and access your complete renewal
                history. Renewals have just got a whole lot easier!
              </Typography>
            </Grid>
            <Grid
              onClick={() => handleClickOpen(ShowMoreImages.renewalImg)}
              item
              xs={12}
              sm={6}
              md={6}
              className={`${classes.GridImageHover} ${classes.centerImage}`}
            >
              <img
                src={renewalImage}
                className={classes.homeLandingImg}
                alt="car renewal"
              />
            </Grid>
            <Grid
              onClick={() =>
                handleClickOpen(ShowMoreImages.sortedResponsiveImg)
              }
              item
              xs={12}
              sm={6}
              md={6}
              className={classes.GridImageHover}
            >
              <img
                src={sortedResponsive}
                className={classes.homeLandingImg}
                alt="car sorted"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.spaceLeft}>
              <Typography
                variant="body1"
                align="center"
                className={typeHeadPaddingStyles}
              >
                Works on your phone, tablet and computer.
              </Typography>

              <Typography
                variant="body1"
                align="center"
                className={typeHeadPaddingStyles}
              >
                Sorted has been carefully designed to work on any device, so you
                can access the service wherever you are.
              </Typography>
            </Grid>
          </Grid>
        </Content>
      )}
      {!store.whitelabel && (
        <Content
          containerClass={`${classes.containerClass} ${classes.contentFaq}`}
        >
          <Grid container direction="column" spacing={4} justify="space-around">
            <Grid item xs={12}>
              <div className={`${classes.infoHome} ${classes.infoGridDiv}`}>
                <Typography
                  variant="h4"
                  align="center"
                  className={`${classes.homeMid} ${classes.padding10}`}
                >
                  Frequently Asked Questions
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  className={`${classes.homeFaqText} ${classes.padding10}`}
                >
                  What documents do I need?
                </Typography>
                <p className={classes.featureText}>
                  All you need is to upload a copy of your ID and a picture of
                  your licence disc. That&apos;s it! No need to fill out any
                  forms – we’ll sort out the rest.
                </p>
                <Typography
                  variant="h5"
                  align="center"
                  className={`${classes.homeFaqText} ${classes.padding10}`}
                >
                  What are the costs?
                </Typography>
                <p className={classes.featureText}>
                  This is what your invoice will look like
                </p>

                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Typography
                          variant="body1"
                          align="left"
                          className={featureTextWithWidth}
                        >
                          <b>Your license disc fee</b>
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="body2"
                          align="center"
                          classes={tableRightTextStyles}
                          className={classes.featureText}
                        >
                          This differs for everyone, and is calculated according
                          to car weight.
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography
                          className={featureTextWithWidth}
                          align="left"
                        >
                          <b>Sorted’s service fee</b>
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          variant="body2"
                          className={classes.featureText}
                          align="center"
                          classes={tableRightTextStyles}
                        >
                          {serviceFeeValue
                            ? formatCurrency(serviceFeeValue)
                            : null}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <Button component={Link} to="/faq" className={classes.btnFaq}>
                    More FAQ
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Content>
      )}
      {!store.whitelabel && (
        <Content
          className={`${footerStyles.headTop}`}
          containerClass={`${classes.containerClass} ${footerStyles.contentMidWhite}`}
        >
          <Grid item sm={12}>
            <Typography
              variant="h4"
              align="center"
              className={`${classes.typoHead} ${classes.padding10}`}
            >
              Ready to get Sorted?
            </Typography>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignupClick}
              >
                {userId ? 'Get Sorted' : 'Sign Up'}
              </Button>
            </div>
          </Grid>
        </Content>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="images"
        aria-describedby="should display the image in a dialog"
        maxWidth="lg"
      >
        <DialogContent>
          <img
            src={images[currentImage]}
            alt=""
            className={classes.homeDialogImage}
          />
        </DialogContent>
      </Dialog>
      <NotAcceptingRenewalsDialog
        open={showMessage}
        onClose={handleCloseMessage}
      />
    </div>
  );
});

export default Home;
